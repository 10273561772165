.orm-reservationInfobar {
  min-height: rem-calc(96px);
  background-color: $orm_bg_dark_color;
  color: $orm_txt_light_color;

  .orm-station__address__container {
    flex: 1;
    width: rem-calc(220px);
    display: inline-block;
  }
  .orm-station__address {
    background-color: $orm_bg_dark_color;
    border: 1px solid $orm_txt_light_color;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    padding: rem-calc(4px) rem-calc(12px);
    margin-bottom: rem-calc(8px);
    max-width: rem-calc(226px);
    margin-right: rem-calc(10px);
  }
  .orm-station__name {
    font-size: rem-calc(15px);
    font-weight: 600;
  }
  .orm-station__street {
    font-size: rem-calc(12px);
    font-weight: 400;
  }
  .orm-cargroup__container {
    flex: 1;
    //width: rem-calc(220px);
    display: inline-block;
    vertical-align: top;
    margin-top: rem-calc(2px);
  }
  .orm-cargroup {
    background-color: $orm_bg_dark_color;
    border: 1px solid $orm_txt_light_color;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    padding: rem-calc(4px) rem-calc(12px);
    margin-bottom: rem-calc(8px);
    max-width: rem-calc(226px);
    margin-right: rem-calc(10px);
  }
  .orm-cargroup__title {
    font-size: rem-calc(9px);
    font-weight: 400;
  }
  .orm-cargroup__text {
    font-size: rem-calc(15px);
    font-weight: 600;
    margin-bottom: 4px;
    display: inline-block;
  }
}

// .orm-datepicker {
//   display: inline-block !important;
//   vertical-align: top;
// }

.orm-module__abholung,
.orm-module__abgabe {
  display: inline-block !important;
  flex: 1;
}
.orm-padding20 {
  padding-top: rem-calc(20px);
  padding-bottom: rem-calc(12px);
}

.orm-reservationInfobar {
  .orm-stationselect {
    min-width: 240px;
  }

  .orm-stationselect__options {
    top: -41px !important;
  }
}

.orm-reservation__data__container {
  background-color: $orm_reservation_bg;
  font-weight: 400;
  &__extra {
    @media (max-width: $sm-viewport) {
      font-size: rem-calc(15px);
    }
    @media (max-width: "380px") {
      font-size: rem-calc(14px);
    }
  }
  &__inner {
    background-color: $orm_reservation_inner_bg;
    padding: rem-calc(24px) rem-calc(32px);
    //word-break: break-all;
    @media (max-width: $sm-viewport) {
      padding: rem-calc(24px) rem-calc(16px);
    }
  }

  .orm-headline__h1 {
    margin-top: rem-calc(7px);
  }
}

.orm-extra__loading {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 50;
  top: 0;
  left: 0;
  display: block;
  background-color: rgba(255, 255, 255, 0.4);

  .orm-loader-spinner {
    font-size: 32px;
    display: inline;
    position: absolute;
    right: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
  }

  &--alt {
    background-color: #f7f7f783;
    .orm-loader-spinner {
      right: 20%;
      top: 70%;
    }
  }
}

.orm-reservationHeader{
  background-color: $orm_header_bg;
}

.orm-reservationHeader__logo {
  // flex: auto;
  // width: 100%;
  // @media only screen and (min-width: $lg-viewport) {

  // }
  img {
    display: block;
  }
  &_image {
    display: block;
    width: 100%;
    min-width: 110px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: contain;
  }
  padding-top: rem-calc(30px);
  padding-bottom: rem-calc(30px);
}

.orm-reservationHeader__indicator {
  // flex: 5;
  // display: flex;
  // justify-content: space-between;
  .orm-indicator {
    // margin: auto;
    font-size: rem-calc(14px);
    font-weight: 100;
    color: $orm_header_step_color;

    p {
      text-align: center;
      text-align: -webkit-center;
    }
    @media only screen and (min-width: $md-viewport) {
      font-size: rem-calc(20px);
    }
    a {
      text-decoration: none;
    }
    p:before {
      width: rem-calc(30px);
      height: rem-calc(30px);
      border-radius: 90px;
      border: 1px solid $orm_header_step_color;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      font-size: rem-calc(20px);
      text-align: center;
      line-height: 1.5;
      display: block;
      @media only screen and (min-width: $xs-viewport) {
        display: inline-block;
      }
    }
    &.active {
      p:before {
        background-color: $orm_header_step_active_bg;
        color: $orm_header_step_active_color;
        border-color: $orm_header_step_active_bg;
      }
    }
    &.checked {
      p:before {
        font-family: "Font Awesome 5 Pro";
        background-color: $orm_header_step_checked_bg;
        border-color: $orm_header_step_checked_bg;
        color: $orm_header_step_checked_color;
        content: "\f00c";
      }
    }
  }
  .orm-indicator__step1 {
    p:before {
      content: "1";
    }
  }
  .orm-indicator__step2 {
    p:before {
      content: "2";
    }
  }
  .orm-indicator__step3 {
    p:before {
      content: "3";
    }
  }
  @media (max-width: 1023px) {
    // flex: 7;
    .orm-indicator {
      font-size: rem-calc(15px);
      p:before {
        width: rem-calc(24px);
        height: rem-calc(24px);
        font-size: rem-calc(15px);
      }
    }
  }
}
.orm-reservationHeader__login {
  // flex: auto;
  // display: flex;
  // justify-content: flex-end;
  margin: auto;
  padding-top: rem-calc(14px);
  padding-bottom: rem-calc(14px);
  font-size: rem-calc(15px);
  font-weight: 600;
  a {
    text-decoration: none;
  }
}

.orm-flexcontainer {
  display: flex;
  justify-content: space-between;
}

#crentSearchBar {
  .timepicker__input {
    display: inline-block;
  }
}
.orm-searchbar {
  &__title {
    color: $orm_txt_headline_searchbar;
  }
}
.orm-searchbar__datepicker {
  background-color: $orm_searchbar_bg;
  border-radius: $border-radius-big;
  max-width: rem-calc(990px);
  color: $orm_searchbar_color;
}

.orm-searchbar__headline {
  padding-left: rem-calc(8px);
  margin-bottom: rem-calc(4px);
  font-size: rem-calc(15px);
  font-weight: 600;
  line-height: 1.33333333;
  strong {
    font-weight: 600;
  }
}

.orm-searchbar__padding {
  padding: rem-calc(24px) rem-calc(8px);
  @media (min-width: $sm-viewport) {
    padding: rem-calc(24px) rem-calc(16px);
  }
}

.orm-button__cta {
  display: inline-block;
  padding: rem-calc(14px 25px);
  font-size: rem-calc(14px);
  font-weight: 600;
  font-family: "Open Sans";
  background-color: $orm_cta_button_color;
  color: $orm_txt_light_color;
  text-decoration: none;
  border-radius: $border-radius-buttons;
  transition: all 0.5s ease;
  margin-bottom: rem-calc(7px);
  max-width: 100%;
  border: 0 none;
  cursor: pointer;

  @media (max-width: "420px") {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: rgba($orm_cta_button_color, 0.3);
  }
  &.small {
    padding: rem-calc(9px 28px);
    @media (max-width: "420px") {
      width: 100%;
      max-width: 55%;
      padding-left: 0;
      padding-right: 0;
    }
  }
  &.outline {
    background-color: transparent;
    border: 2px solid $orm_cta_button_outline_color;
    color: $orm_cta_button_outline_color;
    &:hover {
      border-color: $orm_cta_button_outline_border_hover;
      background-color: $orm_cta_button_outline_hover_color;
      color: $orm_cta_button_outline_hover_text_color;
    }
    &:focus {
      outline: none;
    }
  }
  &.wide {
    padding-top: rem-calc(12.5px);
    padding-bottom: rem-calc(12.5px);
    width: 100%;
    max-width: rem-calc(212px);
    @media (max-width: "420px") {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
    &.orm__cta {
      max-width: 100%;
      @media (min-width: "420px") {
        max-width: rem-calc(260px);
      }
    }
  }
  &:hover {
    background-color: $orm_cta_button_hover_color;
  }
  &.orm-btn__block {
    width: 100%;
  }
}

.orm-button__cta2 {
  display: inline-block;
  padding: rem-calc(14px 25px);
  font-size: rem-calc(14px);
  font-weight: 600;
  font-family: "Open Sans";
  background-color: $orm_cta2_button_color;
  color: $orm_txt_dark_color;
  text-decoration: none;
  border-radius: $border-radius-buttons;
  transition: all 0.5s ease;
  margin-bottom: rem-calc(7px);
  max-width: 100%;
  border: 0 none;
  cursor: pointer;

  @media (max-width: "420px") {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: rgba($orm_cta2_button_color, 0.3);
  }
  &.small {
    padding: rem-calc(9px 28px);
    @media (max-width: "420px") {
      width: 100%;
      max-width: 55%;
      padding-left: 0;
      padding-right: 0;
    }
  }
  &.outline {
    background-color: transparent;
    border: 2px solid $orm_cta2_button_outline_color;
    color: $orm_cta2_button_outline_color;
    &:hover {
      border-color: $orm_cta2_button_outline_border_hover;
      color: $orm_cta2_button_outline_hover_color;
    }
    &:focus {
      outline: none;
    }
  }
  &.wide {
    padding-top: rem-calc(14px);
    padding-bottom: rem-calc(14px);
    width: 100%;
    max-width: rem-calc(212px);
    @media (max-width: "420px") {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
    &.orm__cta {
      max-width: 100%;
      @media (min-width: "420px") {
        max-width: rem-calc(260px);
      }
    }
  }
  &:hover {
    background-color: $orm_cta2_button_hover_color;
    color: $orm_txt_dark_color;
  }
  &.orm-btn__block {
    width: 100%;
  }
}

// html,
// select,
// button,
// a {
//   color: $orm_txt_dark_color;
//   font-family: "Open Sans";
// }
.orm-html {
  color: $orm_txt_dark_color;
  font-family: "Open Sans", Helvetica, Arial;
  font-size: $global-font-size;
  line-height: $global-line-height;
  // word-break: break-word;
  a {
    color: $orm_txt_dark_color;
    font-family: "Open Sans", Helvetica, Arial;
  }
}

.orm-headline {
  &__h1 {
    font-size: rem-calc(42px);
    font-weight: 100;
    margin: 0;
    margin-bottom: rem-calc(30px);
    @media (max-width: $sm-viewport) {
      font-size: rem-calc(32px);
    }
  }
  &__h2 {
    font-size: rem-calc(24px);
    font-weight: 600;
    margin: 0;

    &--alt {
      font-size: rem-calc(32px);
      font-weight: 100;
      margin: 0;
      margin-bottom: rem-calc(30px);
    }
    &--list {
      font-size: rem-calc(32px);
      font-weight: 600;
      margin: 0;
      line-height: 1.2;
      margin-bottom: rem-calc(8px);
      @media (max-width: $sm-viewport) {
        font-size: rem-calc(24px);
      }
    }
  }
  &__h2__subheadline {
    font-size: rem-calc(12px);
    font-weight: 400;
    margin: 0;
    &--list {
      font-size: rem-calc(14px);
      font-weight: 400;
      margin: 0;
    }
  }
  &__h3 {
    font-size: rem-calc(22px);
    font-weight: 600;
    margin: 0;
    margin-bottom: rem-calc(20px);
    &__extras {
      font-size: rem-calc(22px);
      margin: 0;
    }
  }
  &__h3__subheadline {
    font-size: rem-calc(14px);
    font-weight: 400;
    margin: 0;
    margin-bottom: rem-calc(20px);
  }
  &__h4 {
    font-size: rem-calc(16px);
    font-weight: 600;
    margin: 0;
    margin-bottom: rem-calc(10px);
    &.footer {
      color: $orm_txt_light_color;
      margin-bottom: rem-calc(14px);
    }
  }
}

small {
  font-size: 13px;
}

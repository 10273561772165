.vl-calendar-month {
  padding: $vl-calendar-month-padding;

  &__title {
    margin-bottom: 20px;
    text-align: center;
    font-weight: 600;
  }

  &__week-numbers-column {
    padding-top: 24px;
  }
  .vl-flex.hovering {
    .selected {
      background: rgba($vl-selected-day-bg-color, 0);
      color: $vl-weekday-text-color;
      font-weight: 400;
      &:before {
        visibility: hidden;
      }

      &:hover {
        background: rgba($vl-selected-day-bg-color, 1);
        color: $vl-selected-day-text-color;
        font-weight: 800;
        &:before {
          visibility: visible;
        }
        &:after {
          visibility: visible;
        }
      }
    }
  }

  &__week-number {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
    width: 100%;
    height: 24px;
    color: $vl-gray-0;
    font-size: 10px;
  }

  &__week-day {
    display: inline-block;
    margin-bottom: 10px;
    width: 14%;
    text-align: center;
    color: $vl-weekday-text-color;
    font-size: 12px;
  }

  &__day {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
    cursor: pointer;
    width: 14%;
    height: 24px;

    @for $i from 1 through 6 {
      &--offset-#{$i} {
        margin-left: calc(#{$i} * 14%);
      }
    }

    &.disabled {
      color: $vl-gray-1;
      pointer-events: none;

      &.clickable {
        pointer-events: all;
      }

      &--first {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &--last {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &.isOccupied {
      background-color: $orm_calendar_occupied_color;
      font-weight: 800;
      color: white;
    }

    &.isPartially {
      background-color: $orm_calendar_partially_available;
      color: white;
      font-weight: 800;
    }

    &.selected {
      background: $vl-selected-day-bg-color;
      color: $vl-selected-day-text-color;
      font-weight: 800;

      &.disabled {
        border: $vl-selected-disabled-border;
        background: $vl-selected-disabled-day-bg-color;
        color: $vl-selected-disabled-day-text-color;
      }

      &--first {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        position: relative;
        &::before {
          content: "";
          border-style: solid;
          border-width: 11px 0 12px 10px;
          border-color: transparent transparent transparent $white;
          position: absolute;
          top: 1px;
          left: -5px;
        }
      }

      &--last {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        position: relative;
        &::after {
          content: "";
          border-style: solid;
          border-width: 11px 10px 12px 0;
          border-color: transparent $white transparent transparent;
          position: absolute;
          top: 1px;
          right: -5px;
        }
      }
    }

    &:hover {
      border-radius: 0;
      background: $vl-selected-day-bg-color;
      color: $vl-selected-day-text-color;
      font-weight: 800;
      position: relative;
      &::before {
        content: "";
        border-style: solid;
        border-width: 11px 0 12px 10px;
        border-color: transparent transparent transparent $white;
        position: absolute;
        top: 1px;
        left: -5px;
      }
    }
  }
}

.vl-calendar-month__day {
  transition: all 0.2s ease-in;
}

.vl-calendar.startDate .vl-calendar-month__day.disabled {
  pointer-events: all;
}
.vl-calendar__start {
  .vl-calendar-month__day:hover::after {
    display: none;
  }
}
.vl-calendar__end {
  .vl-flex.hovering {
    .selected--first {
      background: rgba($vl-selected-day-bg-color, 1);
      color: $vl-selected-day-text-color;
      font-weight: 800;
      &:before {
        visibility: visible;
      }
    }
  }

  .vl-calendar-month__day:hover::before {
    display: none;
  }
  .vl-calendar-month__day:hover::after {
    content: "";
    border-style: solid;
    border-width: 11px 10px 12px 0;
    border-color: transparent $white transparent transparent;
    position: absolute;
    top: 1px;
    right: -5px;
  }

  .vl-calendar-month__day.selected--first {
    border-radius: 0;
    background: $vl-selected-day-bg-color;
    color: $vl-selected-day-text-color;
    font-weight: 800;
    position: relative;
    &::before {
      content: "";
      border-style: solid;
      border-width: 11px 0 12px 10px;
      border-color: transparent transparent transparent $white;
      position: absolute;
      top: 1px;
      left: -5px;
    }
  }
}
.vl-calendar__availableplan {
  text-align: left;
  width: auto;
  .vl-calendar__arrow {
    top: 8px;
    &--back {
      left: 0;
    }
    &--forward {
      right: 0;
    }
  }
  .vl-calendar__month {
    margin-bottom: 16px;
  }
  .vl-calendar-month:first-of-type {
    padding: 0 20px 0 0;
  }
  .vl-calendar-month:last-of-type {
    padding: 0 0 0 20px;
  }

  .vl-calendar-month:first-of-type:last-of-type {
    padding: 0 20px;
  }

  .vl-calendar-month__day {
    pointer-events: none;
    &.selected {
      background-color: transparent;
      border-top: 2px solid rgba($vl-selected-day-bg-color, 1);
      border-bottom: 2px solid rgba($vl-selected-day-bg-color, 1);
      color: $vl-weekday-text-color;

      &--first {
        border-left: 2px solid rgba($vl-selected-day-bg-color, 1);
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;

        &::before {
          display: none;
        }
      }

      &--last {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-right: 2px solid rgba($vl-selected-day-bg-color, 1);
        &::after {
          display: none;
          border-radius: 2px;
        }
      }

      &.isOccupied {
        background-color: $orm_calendar_occupied_color;
        font-weight: 800;
        color: white;
      }

      &.isPartially {
        background-color: $orm_calendar_partially_available;
        color: white;
        font-weight: 800;
      }
    }
  }
}

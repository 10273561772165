.vl-calendar {
  display: inline-block;
  position: absolute;
  z-index: 500;
  background-color: transparentize($white, 0.05);
  padding: $vl-calendar-padding;
  text-align: center;
  color: $vl-calendar-text-color;
  font-size: 14px;
  user-select: none;
  left: 0;
  right: 0;
  margin: auto;
  top: -72%;
  border-radius: $border-radius-big;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: rem-calc(560px);

  &__availableplan {
    position: relative;
    border-radius: 0;
    box-shadow: none;
  }

  &__month {
    display: inline-block;
    margin-bottom: 30px;
    width: $vl-calendar-month-width;
    max-width: 80vw;
  }

  &__title {
    font-size: rem-calc(19px);
    margin-bottom: rem-calc(10px);
    //text-align: left;
    //padding-left: rem-calc(50px);
  }

  &__arrow {
    position: absolute;
    top: 48px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 75%;
    cursor: pointer;
    width: 24px;
    height: 24px;

    &--back {
      left: 15px;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAAGgrv1cAAAAAXNSR0IArs4c6QAAAK5QTFRFAAAAgICAVVVVQEBAOTlVK0BAJztOMzNEJzFFLjdALDVGLDNCKDZDJjNAKjQ+JzFAKDE/JzRBKDNCKDJAJzE+JjI/JTI+JzM/JTQ/KDM+JjQ+JjNAJTJAJjJAJTM/JzJAJTI/JTE/JjE/JTI+JjI/JjI/JjI/JjI+JjE/JjI/JjE/JTI/JjE+JTE/JTE+JTE+JTI/JjI+JjE/JTI+JjE+JjI+JjI/JTI/JTE+JTE+hLrwHAAAADl0Uk5TAAIDBAkMDQ8aHB0jJigxNDk7RkxOUVJVWVpeX2BrbnB1lpukqq6vtLbCz9PZ6Onq8PLz9fn6+/z90I45zgAAARhJREFUGBkFwYkCjAAABcBxLF3uHKGVFGEdRer9/4+ZoS/1zyhhA3ysOXHa/Ow6nNRX3Ba0GJh8JS4vuu5+7N5yMBNoMzWXbl8heDViHt0aj35UVVURS8frCYR1AKSAMUmOAiiX7dYD1r27NFNaOAfgbBlnHBCCP/dh6oVfT6qqqjQ3YX/fdd0bDzbBcRe6xVHjhL1UBDCsuKaG+QTFkSQjgGI8kiRJjrEAcD0z1wD1nPMKijUD0F2BIWtBeew1eP73yz1AvR+lJR08/v3tIQBdFlsu+J4kSZLkKZdsbmng5b/P9wBoctNnAt6cn+4ATOkZMwPefQDMGaE9zwEAhvNsAe2afeqay6Xppj1rCwBlv9y27bb0JeA/5uonxPjnqJ8AAAAASUVORK5CYII="); // sass-lint:disable-line max-line-length
      &.disabled {
        opacity: 0.1;
      }
    }

    &--forward {
      right: 15px;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAAGgrv1cAAAAAXNSR0IArs4c6QAAAJ9QTFRFAAAA////K0BAJztOMT1JKTNHJzFFLjdALDVGJzZGLDNCKDZDJzM/JjFBKDJAJzE+JzI+JjJAKDM+JzJAJzRAJzFAJjJAJTM/JzJAJTNAJTI/JjM/JjI+JjI/JjE/JzI/JzI+JjI/JTJAJTE/JjI/JjE/JTI/JjI/JjI/JjE/JjI/JTI/JjE+JjI+JjE/JjE+JjI+JTI/JTE+JTE+JTE+Sw1VBgAAADR0Uk5TAAEMDRUZGhwdISMmQUNMTlZXWltjaGtucHR1eX+FhoqLjpCWmpudqq62w8bZ8vP5+vz9/rSzoTkAAAERSURBVBgZBcGLwoxgAAXA+Re1NsSHltwvqahUzvs/mxm6WocaNsC/GydOm1KCk9sdy4wWPSMRlxeliJ0HBxOBNmNzKfsKgWAaLI3H1+v16UnMBQRh7QGpYEiSowLqeVs6wLqXSzOmhbMHzpZhwutvCAFf3zF2wvV6vf5+qVmEUkr589mjTcD9O2V23PDqJ/ZaFUC/4p4bTCeojiQZAFTDkSRJcgwVgPuZ6QZwm3LeQbWmB559AvqsFfWx3wA+5CO47UdtTgHg4de/N1Ay23LB3yRJkmR/wiWbJQ0A3ucrNFl0GQG8zQ9gTMeQCfD8ywMwZYD2PHsA6M+zBbRr9rE0l0tTxj1rCwB1Ny/btsxdDfgPirgkMNekg80AAAAASUVORK5CYII="); // sass-lint:disable-line max-line-length
      &.disabled {
        opacity: 0.1;
      }
    }
  }
}

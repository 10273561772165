.orm-headerNavigation {
  font-size: rem-calc(14px);
  font-weight: 600;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  overflow-x: auto;
  li {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0 0 0 rem-calc(24px);

    &:first-child {
      padding-left: 0;
    }

    a {
      display: block;
      text-decoration: none;
      &:hover,
      &:active,
      &:focus,
      &.router-link-active {
        text-decoration: underline;
      }
    }
  }
  .orm-filter__dropdown {
    right: rem-calc(16px);
  }
  @media (min-width: $lg-viewport) {
    //margin-bottom: rem-calc(24px);
    position: relative;
    overflow: visible;
    .orm-filter__dropdown {
      right: rem-calc(0);
    }
  }
}

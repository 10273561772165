.orm {
  &-btn {
    border-radius: $border-radius-small;
    background-color: $orm_cta_button_color;
    border: 0;
    color: $white;
    padding: 6px 12px;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
  &-btn--large {
    width: 100%;
    &:focus {
      outline: none;
    }
  }
  &-btn--searchbar {
    height: calc(48px);
    font-family: "Open Sans";
    font-size: rem-calc(14px);
    font-weight: 600;
    transition: background-color ease-in-out 0.25s, color ease-in-out 0.25s;
    &:focus {
      outline: none;
    }
    &.disable {
      opacity: 0.5;
      pointer-events: none;
    }
    &:hover {
      background-color: $orm_cta_button_hover_color;
    }
    @media (min-width: "420px") and (max-width: "576px") {
      max-width: rem-calc(250px);
    }
    @media (min-width: "646px") and (max-width: "768px") {
      max-width: rem-calc(250px);
    }
    @media (min-width: $md-viewport) and (max-width: $lg-viewport) {
      max-width: rem-calc(250px);
    }
  }
}

.orm-reservationCarselect {
  background-color: $orm_bg_lt_grey_color;
  padding-bottom: rem-calc(50px);
  min-height: 100vh;
}

.orm-carfilter {
  display: flex;
  &__element {
    font-size: rem-calc(15px);
    font-weight: 600;
    padding-right: rem-calc(40px);
    padding-top: rem-calc(32px);
    padding-bottom: rem-calc(32px);
  }
  &__infolink {
    margin-left: rem-calc(5px);
  }
}
.orm-caroffers {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &__element {
    // max-width: 428px;
    background-color: $orm_bg_light_color;
    height: 100%;
    .orm-carimage {
      width: 100%;
      height: auto;
      overflow: hidden;

      img {
        width: 100%;
        display: block;
        transition: transform 500ms ease;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
    .orm-cartitle {
      text-align: center;
      padding-left: rem-calc(16px);
      padding-right: rem-calc(16px);
    }
    .orm-cardetails {
      display: flex;
      justify-content: space-between;
      padding-top: rem-calc(5px);
      padding-left: rem-calc(16px);
      padding-right: rem-calc(16px);
      @media (min-width: $sm-viewport) {
        padding-left: rem-calc(36px);
        padding-right: rem-calc(36px);
      }

      .orm-carequip {
        align-self: flex-end;
        .orm-equip__txt__icons {
          margin: 0;
          margin-bottom: rem-calc(8px);
          font-size: rem-calc(12px);
          font-weight: 400;

          .orm-equip__icon {
            margin-right: rem-calc(12px);
          }
        }
        .orm-equip__txt__freemiles {
          margin: 0;
          font-size: rem-calc(12px);
          font-weight: 400;
        }
      }
      .orm-carprice {
        text-align: end;
        min-height: rem-calc(75px);
        .orm-price__txt {
          margin: 0;
          font-size: rem-calc(30px);
          font-weight: 600;
          &__surtitle,
          &__subtitle {
            margin: 0;
            font-size: rem-calc(12px);
            font-weight: 400;
          }
        }
      }
    }
    .orm-carbutton {
      text-align: center;
      padding-top: rem-calc(16px);
      padding-bottom: rem-calc(32px);
      padding-left: rem-calc(16px);
      padding-right: rem-calc(16px);
      @media (min-width: $sm-viewport) {
        padding-left: rem-calc(36px);
        padding-right: rem-calc(36px);
      }
    }
  }
}

.orm-car__error {
  color: $orm_form_error_color;
  font-style: italic;
  font-size: 14px;
}

.orm-carbutton--bottom {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  @media (max-width: $sm-viewport) {
    justify-content: center;
  }
  button,
  .orm-car__error {
    margin-top: auto;
    margin-bottom: 0;
  }
}

.orm-cardetails--flex {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  @media (max-width: $sm-viewport) {
    justify-content: center;
  }
  .orm-cardetails__container {
    flex: 1;

    .orm-cardetails {
      padding: 0;
      margin-bottom: rem-calc(24px);
      .orm-carprice {
        .orm-price__txt__surtitle {
          font-size: rem-calc(16px);
        }
        .orm-price__txt {
          font-size: rem-calc(32px);
          @media (max-width: $sm-viewport) {
            font-size: rem-calc(24px);
          }
        }
      }
    }
    &--bottom {
      margin-top: auto;
      margin-bottom: 0;
    }
    &--center {
      margin-top: auto;
      margin-bottom: auto;
    }
    &--top {
      margin-top: 0;
      margin-bottom: auto;
    }
  }
}

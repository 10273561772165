.orm-availabillity__title{
    font-weight: bold;
}

.orm-availabillity__legend {
  &_item {
    margin-right: rem-calc(16px);
    display: inline-block;
    * {
      float: left;
      margin-right: rem-calc(8px);
      position: relative;
    }
  }
  &_selection {
    border: 3px solid rgba($vl-selected-day-bg-color, 1);
    display: inline-block;
    width: 32px;
    height: 20px;
    border-radius: 3px;

  }
  &_occupied {
    background-color: $orm_calendar_occupied_color;
    display: inline-block;
    width: 32px;
    height: 20px;
  }
  &_partially {
    background-color: $orm_calendar_partially_available;
    display: inline-block;
    width: 32px;
    height: 20px;
  }
}

.orm {
  &-timepicker__input {
    width: auto;
    height: 20px;
    // border: 1px solid grey;
    display: inline-block;
    font-size: rem-calc(15px);
    font-weight: 600;
    line-height: 1.333333333;
    cursor: pointer;
    i {
      //position: absolute;
      margin-left: rem-calc(3.2px);
      margin-top: rem-calc(2px);
    }
    &.active {
      background-color: $vl-selected-disabled-day-bg-color;
    }

    &.disable {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &-timepicker__options {
    background-color: $white;
    height: 257px;
    overflow: hidden;
    border-radius: $border-radius-big;
    text-align: center;
    // padding-top: 32px;
    // padding-bottom: 32px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    z-index: 500;
    position: absolute;
    top: -202%;

    @media screen and (max-height: 193px) {
      position: fixed;
      width: auto;
      top: 5px;
      max-height: calc(100vh - 50px);
      overflow: auto;
    }

    &_inner {
      overflow-x: hidden;
      height: 193px;
      background-color: $orm_bg_light_color;

      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  &-timpicker__up,
  &-timpicker__down {
    height: 32px;
    padding: 10px;
    box-sizing: border-box;
    color: $orm_black;
    cursor: pointer;
    .fa-sort-down {
      top: -6px;
      position: relative;
    }
  }
  &-timepicker__option {
    padding: 6px 10px;
    border-top: 1px solid transparentize($grey, 0.8);
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    min-width: 100px;
    color: $orm_black;
    transition: background-color ease-in-out 0.25s, color ease-in-out 0.25s;
    &:nth-child(even) {
      background-color: $grey-light;
    }
    &--selected {
      background-color: $orm_timepicker_selected !important;
      color: $orm_txt_light_color !important;
    }
    &:hover,
    &:focus {
      background-color: $orm_bg_dark_color;
      color: $orm_txt_light_color;
    }
  }
}

.orm-locationMap {
  &__title {
    text-align: center;
    font-size: rem-calc(42px);
    font-weight: 100;
    margin-top: rem-calc(72px);
    margin-bottom: rem-calc(30px);
    color: $orm_txt_headline_locationmap;
    @media (max-width: $sm-viewport) {
      font-size: rem-calc(32px);
      margin-top: rem-calc(56px);
    }
  }
  &__mapContainer {
    border-radius: $border-radius-big $border-radius-big 0 0;

    overflow: auto;
    @media only screen and (min-width: $md-viewport) {
      border-radius: $border-radius-big 0 0 $border-radius-big;
    }
  }
  &__stationsList {
    background-color: $orm_bg_light_color;
    height: 450px;

    overflow: auto;
    &__container {
      max-height: 450px;
      border-radius: 0 0 $border-radius-big $border-radius-big;
      @media only screen and (min-width: $md-viewport) {
        border-radius: 0 $border-radius-big $border-radius-big 0;
      }
    }
  }
}

.orm-stationsList {
  &__button {
    padding-top: rem-calc(8px);
    padding-bottom: rem-calc(8px);
    @media (min-width: "421px") and (max-width: $md-viewport) {
      text-align: right;
      .orm-btn__block {
        max-width: rem-calc(250px);
      }
    }
  }
  &__station {
    padding: rem-calc(16px);
    background-color: $orm_map_stationlist_bg1;
    color: $orm_map_stationlist_color1;
    &:nth-child(even) {
      background-color: $orm_map_stationlist_bg2;
      color: $orm_map_stationlist_color2; 
    }

    &:not(.selected) {
      cursor: pointer;
    }
    .orm-station__title {
      display: flex;
      justify-content: space-between;
      p {
        margin: 0;
        padding-left: rem-calc(12px);
        font-size: rem-calc(15px);
        font-weight: 600;
      }
      margin-bottom: rem-calc(4px);
    }
    .orm-station__name__container {
      display: flex;
      justify-content: space-between;
    }
    .orm-station__name {
      font-size: rem-calc(15px);
      font-weight: 600;
    }
    .orm-station__street {
      font-size: rem-calc(12px);
      font-weight: 400;
    }
    .orm-station__icons {
      .orm-car__rental {
        color: $orm_map_marker_rental_color;
      }
      .orm-car__share {
        color: $orm_map_marker_sharing_color;
      }
    }
    .orm-station__distance {
      position: absolute;
      right: rem-calc(42px);
      text-align: right;
    }
    .orm-station__address {
      background-color: $orm_bg_light_color;
      border: 1px solid $orm_txt_dark_color;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      padding: rem-calc(4px) rem-calc(12px);
      margin-bottom: rem-calc(8px);
      max-width: rem-calc(226px);
      .orm-station__address__icon {
        font-size: rem-calc(20px);
        align-self: flex-end;
      }
    }
    .orm-station__alt__text {
      display: inline-block;
      font-size: rem-calc(12px);
      margin-bottom: rem-calc(22px);
      &:before {
        font-family: "Font Awesome 5 Pro";
        font-size: rem-calc(17px);
        font-weight: 100;
        content: "\f0c8";
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        margin-right: 5px;
      }
      &:hover {
        &:before {
          content: "\f14a";
        }

        cursor: pointer;
      }
    }
  }
}

.orm-locationMap__stationsList {
  .orm-stationselect,
  .orm-dateselect {
    background-color: $orm_map_input_bg;
    color: $orm_map_input_color;
  }
  .orm-stationselect__options {
    z-index: 800;
  }
}
